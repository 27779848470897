<template>
    <div class="bg-white rounded">
        <div class="p-24">
            <app-heading-3>My Signatures</app-heading-3>
            <div class="mt-1">
                <div class="text-bold mb-6">Saved Signatures</div>
                <div class="saved-signature" v-if="signatureUrl">
                    <b-overlay :show="loading" class="w-100 h-100">
                        <img :src="signatureUrl" alt="" class="w-100 h-100">
                    </b-overlay>
                </div>
               
                <div class="text-small" v-else>No signature is saved, please create</div><br>
                <b-spinner variant="primary" v-if="loading" label="Spinning"></b-spinner>
            </div>

            <div class="mt-1">
                <b-button variant="primary" v-if="signatureUrl"  @click="()=>$refs.createSignature.showModal()">Edit</b-button>
                <b-button variant="primary"  v-else @click="()=>$refs.createSignature.showModal()">Create Signatures</b-button>
            </div>    
        </div>
        <create-signatures ref="createSignature"  @onCreated="getSignature()"></create-signatures>  
    </div>
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import UploadSignatures from './UploadSignatures.vue'
import CreateSignatures from './CreateSignatures.vue'
import { BButton, BOverlay, BSpinner} from 'bootstrap-vue'
import {get_signature} from '@/apis/others/user'
export default {
    components: {
        AppHeading3,
        BButton,
        UploadSignatures,
        CreateSignatures,
        BOverlay,
        BSpinner
    },
    data(){
        return{
            show:false,
            signatureUrl:'',
            loading:false
        }
    },
    mounted(){
        this.getSignature()
    },
    methods:{
        getSignature(){
            this.loading=true;
            this.signatureUrl='';
            get_signature().then(res=>{
                this.loading=false;
                this.signatureUrl=res.data.url;
            }).catch(err=>{
                this.loading=false;
                this.signatureUrl='';
            })
        }
    }
}
</script>
<style scoped>
.p-24{
    padding: 24px;
}
.ml-8{
    margin-left: 8px;
}
.mb-6{
    margin-bottom: 6px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
}
.upload-text{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
}

.text-bold{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #4B465C;
}
.text-simple{
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
}
.text-small{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #667085;
}
.saved-signature{
    border-radius: 6px;
    background-color: #f8fafc;
    width: 320px;
    height: 158px;
}
</style>