<template>
    <div>
        <b-tabs pills v-model="activeTab" active-nav-item-class="bg-light-primary border-0 shadow-none tab-font" @input="onTabChange">
            <b-tab>
                <template #title >
                    <feather-icon icon="UserIcon" size="18"></feather-icon>
                    <span class="tab-font">Account</span>
                </template>
                <account-settings/>            
            </b-tab>
            <b-tab>
                <template #title >
                    <feather-icon icon="LockIcon" size="18"></feather-icon>
                    <span class="tab-font">Security</span>
                </template>
                <security-settings/>  
            </b-tab>

            <b-tab >
                <template #title >
                    <feather-icon icon="FileTextIcon" size="18"></feather-icon>
                    <span class="tab-font">Billing & plans</span>
                </template>
                <billing-settings/>  
            </b-tab>
            <b-tab  v-if="getUserRole=='service_provider'">
                <template #title >
                    <feather-icon icon="DollarSignIcon" size="18"></feather-icon>
                    <span class="tab-font">Subscriptions</span>
                </template>
                <subscriptions/>  
            </b-tab>
            <b-tab>
                <template #title >
                    <feather-icon icon="BellIcon" size="18"></feather-icon>
                    <span class="tab-font">Notifications</span>
                </template>
                <notifications-settings/>
            </b-tab>
            <b-tab>
                <template #title >
                    <feather-icon icon="Edit2Icon" size="18"></feather-icon>
                    <span class="tab-font">Signatures</span>
                </template>
                <signatures-settings/>
            </b-tab>
            <b-tab v-if="getQuickbook()">
                <template #title >
                    <img :src="require(`@/assets/images/app-icons/${activeTab==5?'quickbooks_active':'quickbooks'}.svg`)" alt="" class="mr-6">
                    <span class="tab-font">QuickBooks</span>
                </template>
                <quickbooks/>
            </b-tab>
        </b-tabs>   
    </div>
</template>
<script>
import {BTabs, BTab} from 'bootstrap-vue'
import AccountSettings from '@/components/functional-components/other/settings/Account'
import SecuritySettings from '@/components/functional-components/other/settings/Security'
import BillingSettings from '@/components/functional-components/other/settings/Billing&Plans'
import NotificationsSettings from '@/components/functional-components/other/settings/Notifications'
import Subscriptions from '@/components/functional-components/other/settings/Subscriptions' 
import SignaturesSettings from '@/components/functional-components/other/settings/signatures/Signatures'
import Quickbooks from '@/components/functional-components/other/settings/Quickbooks'
import {getUserData} from '@/auth/utils'
export default{
    components:{
        BTabs,
        BTab,
        AccountSettings,
        SecuritySettings,
        BillingSettings,
        NotificationsSettings,
        SignaturesSettings,
        Quickbooks,
        Subscriptions
    },
    data(){
        return{
            activeTab:0
        }
    },
    mounted(){
        // console.log('this is billing tab:',this.$route.query.tab)
        if(this.$route.query.tab=='billing'){
            setTimeout(()=>this.activeTab = 2,[100])
        }else if(this.$route.query.tab=='signature'){
            setTimeout(()=>this.activeTab = 4,[100])  
        }else if(this.$route.query.tab=='subscriptions'){
            setTimeout(()=>this.activeTab = 3,[100])  
        }else if(this.$route.query.code){
            setTimeout(()=>this.activeTab = 5,[100])  
        }else{
            setTimeout(()=>this.activeTab = 0,[100])   
        }
    },
    computed:{
        getUserRole(){
            const user=getUserData()
            return user.role
        },
    },
    methods:{
        onTabChange(index){
            this.activeTab = index
        },
        getQuickbook(){
            const user=getUserData()
            return user.quickbooksAvailable
        },
    }
}
</script>
<style scoped>
.tab-font{
    font-size: 15px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    text-wrap: nowrap !important;
}
.mr-6{
    margin-right: 6px;
}
.nav.nav-pills{
    flex-wrap: nowrap !important;
    overflow-x:auto !important;
}
</style>